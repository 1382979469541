import { Link } from '@remix-run/react';
import { cn } from '@cardo/lib';
import { IoArrowForwardSharp } from 'react-icons/io5';
import type {
  CreditCardTag,
  FeaturedCardsComponent,
  OmitComponentAttrs,
} from '@cardo/types';
import { Button } from '@cardo/ui';
import CardCarousel from '../CardCarousel';
import { TermsAndRates } from './CreditCard';
import { getGoRedirectPath } from '~/lib/utils';

const ViewMoreButton = ({
  className = '',
  to = '/credit-cards/reviews',
}: {
  className?: string;
  to?: string;
}) => (
  <Link to={to} className={className}>
    <Button primary className={'rounded-[25px] text-sm font-medium'}>
      View More Cards
    </Button>
  </Link>
);

export default function FeaturedCards({
  heading,
  subheading,
  cards,
  seeMoreUrl,
}: OmitComponentAttrs<FeaturedCardsComponent>) {
  const featuredCards = cards
    .filter((card) => card.card?.data)
    .map((card, index, array) => (
      <div
        className={cn(
          'w-full pb-2 pt-4 sm:flex-1 px-0 lg:px-12 h-full xl:w-auto',

          index < array.length - 1
            ? 'border-r-0 lg:border-r-[1.5px] border-gray-200'
            : '',
        )}
        key={card.id}
      >
        <FeaturedCard
          cardArtUrl={card.card.data?.attributes.cardArtUrl}
          cardName={card.card.data?.attributes.cardName}
          marketingLine={card.card.data?.attributes.introOfferShort}
          link={getGoRedirectPath(card.card.data)}
          calloutTag={card.card.data.attributes.calloutTag?.data}
          ratesAndFeesLink={card.card.data?.attributes.ratesAndFeesLink}
        />
      </div>
    ));
  return (
    <div className="flex flex-grow flex-col p-5 md:p-12 items-center bg-[#073E5D] w-full">
      {heading && (
        <h2
          className={cn('text-center text-2xl text-white ', {
            'mb-3 sm:mb-14': !subheading,
            'mb-3': !!subheading,
          })}
        >
          {heading}
        </h2>
      )}
      {subheading && <h5 className="mb-14">{subheading}</h5>}
      <div className="flex flex-col lg:flex-row gap-2 lg:gap-5  h-full justify-center items-center  w-full">
        <div className="group flex flex-col items-center lg:items-start  gap-3 text-lg font-bold no-underline px-0  lg:px-5  ">
          <h4 className="text-white">💳 Featured Credit Cards</h4>
          <ViewMoreButton to={seeMoreUrl} className="hidden lg:flex" />
        </div>
        <div className="hidden lg:grid grid-cols-1 lg:grid-cols-3 gap-5 lg:gap-0  ">
          {featuredCards}
        </div>
        <CardCarousel className="flex lg:hidden flex-col pb-1" slidesToShow={1}>
          {featuredCards}
        </CardCarousel>
        <ViewMoreButton to={seeMoreUrl} className="flex lg:hidden" />
      </div>
    </div>
  );
}

interface FeaturedCardProps {
  cardArtUrl: string | null;
  cardName: string;
  marketingLine: string | null;
  link: string;
  calloutTag?: CreditCardTag | null;
  ratesAndFeesLink: string | null;
}

function FeaturedCard({
  cardArtUrl,
  cardName,
  marketingLine,
  link,
  calloutTag,
  ratesAndFeesLink,
}: FeaturedCardProps) {
  return (
    <div className="text-white h-full flex space-x-3 no-underline">
      <div className="w-full flex flex-col gap-5 xl:gap-3 h-full items-center lg:items-start justify-center bg-transparent">
        {calloutTag && (
          <div
            className={cn(
              'rounded-md font-bold py-1 px-3 w-fit ml-[3px] flex items-center gap-1.5 text-sm',
              calloutTag.attributes.bgColor === 'green' &&
                'bg-[#16A085] text-white',
              calloutTag.attributes.bgColor === 'blue' &&
                'bg-[#207AB6] text-white',
              !calloutTag.attributes.bgColor &&
                'border bg-blue-50 text-[#0E44AC]',
            )}
          >
            {calloutTag.attributes.emojiIcon && (
              <span>{calloutTag.attributes.emojiIcon}</span>
            )}
            {calloutTag.attributes.icon?.data &&
              !calloutTag.attributes.emojiIcon && (
                <figure className="w-4 h-4 flex-shrink-0">
                  <img
                    src={calloutTag.attributes.icon.data.attributes.url}
                    alt={calloutTag.attributes.name}
                    className="w-full h-full"
                  />
                </figure>
              )}
            {calloutTag.attributes.name && (
              <span>{calloutTag.attributes.name}</span>
            )}
          </div>
        )}
        {cardArtUrl && (
          <div className="flex flex-col  xl:flex-row w-full gap-3 xl:gap-6 items-center h-full justify-between text-center xl:text-start">
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              className="flex max-w-[60%] lg:max-w-[120px] flex-grow w-full"
            >
              <img
                src={cardArtUrl}
                alt={`card art for the ${cardName}`}
                className="mx-auto h-full w-full rounded-lg object-contain"
              />
            </a>
            <div className="flex flex-col w-full items-center lg:items-start gap-2">
              <a
                href={link}
                target="_blank"
                rel="noreferrer"
                className="flex flex-col w-full items-center lg:items-start gap-2 text-inherit no-underline"
              >
                <h5 className="line-clamp-2">{cardName}</h5>
                {marketingLine && <p className="font-bold">{marketingLine}</p>}
              </a>
              <TermsAndRates
                ratesAndFeesLink={ratesAndFeesLink}
                className="text-white"
              />
            </div>
          </div>
        )}
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className="flex flex-row items-center justify-center lg:justify-start text-white text-base font-bold no-underline group w-full"
        >
          <span className="mr-2 whitespace-nowrap">View offer</span>
          <IoArrowForwardSharp className="-rotate-45 text-base transition-transform group-hover:rotate-0" />
        </a>
      </div>
    </div>
  );
}
