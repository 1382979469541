import { cn } from '@cardo/lib';
import type {
  TripReportCardsComponent,
  OmitComponentAttrs,
} from '@cardo/types';
import { TripReportCard } from '@cardo/ui';
import CardCarousel from '../CardCarousel';
import { TravelGoalsSelect } from '~/routes/resource_.travel-goals';

export default function TripReportCards({
  heading,
  subheading,
  tripReports,
}: OmitComponentAttrs<TripReportCardsComponent>) {
  const reportCards =
    tripReports?.data &&
    tripReports.data.map((tripReport, index) => (
      <div
        key={index}
        className="col-span-12 md:col-span-6 lg:col-span-4 h-full text-inherit "
      >
        <TripReportCard
          tripReport={tripReport ?? null}
          TravelGoalsSelect={TravelGoalsSelect}
        />
      </div>
    ));

  return (
    <div className="flex flex-grow flex-col items-center">
      {heading && (
        <h2
          className={cn('text-center text-2xl', {
            'mb-8 md:mb-14': !subheading,
            'mb-3': !!subheading,
          })}
        >
          {heading}
        </h2>
      )}
      {subheading && <h5 className="mb-14">{subheading}</h5>}

      <div className="hidden md:grid grid-cols-12 gap-x-0 md:gap-x-8 gap-y-8 w-full">
        {reportCards}
      </div>

      <CardCarousel className="flex md:hidden flex-col pb-1">
        {reportCards}
      </CardCarousel>
    </div>
  );
}
